<template>
  <div class="m-5 pb-5">
    <h4>{{ sharedItem.name }}</h4>
    <p>{{ sharedItem.description }}</p>
    <div>
      <b-img
        class="mb-2"
        style="width: 70%;"
        v-if="imageUrl"
        :src="imageUrl"
        fluid
        alt="Fluid image"
      ></b-img>
    </div>
    <b-button
      class="mr-2 ml-0 green-button"
      @click="$emit('open-name-modal')"
    >
      <b-icon
        icon="plus-circle-fill"
        class="mr-2"
        aria-hidden="true"
        scale="1"
      ></b-icon>
      {{ $t('webmag.select') }}
    </b-button>
    <b-button
      class="mr-0 ml-0 btn-secondary"
      @click="openSharedTemplateInPreview(sharedItem)"
      target = '_blank'
    >
      <b-icon
        icon="link"
        class="mr-2"
        aria-hidden="true"
        scale="1"
      ></b-icon>
      {{ $t('webmag.preview') }}
    </b-button>
    <modal-preview-page
      v-if="showPreviewModal && iframeSourceForPreview && itemName"
      :iframe-src="iframeSourceForPreview"
      :preview-action-object="previewActionObject"
      :itemName="itemName"
      :group-domain="groupDomain"
      @preview-closed="previewModalClosed"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalContentShowItemPreview',
  components: {
    ModalPreviewPage: () => import('@/components/modals/ModalPreviewPage.vue'),
  },
  props: {
    groupId: {
      type: Number,
      default: null,
    },
    groupDomain: {
      type: String,
      default: null,
    },
    groupSlug: {
      type: String,
      default: null,
    },
    groupPath: {
      type: String,
      default: null,
    },
    parentItem: {
      type: Object,
      default: null,
    },
    parentSlugPath: {
      type: String,
      default: null,
    },
    sharedItem: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showAcknowledgeModal: false,
    showPreviewModal: false,
    iframeSourceForPreview: null,
    previewActionObject: null,
    itemName: null,
  }),
  computed: {
    imageUrl() {
      if (this.sharedItem.thumbnails) {
        if (this.sharedItem.thumbnails.cover_image && this.sharedItem.thumbnails.cover_image.url) {
          const image = this.sharedItem.thumbnails.cover_image;
          const height = 600 * (image.height / image.width);
          return this.$makeCloudImage(
            image.url,
            {
              width: 600,
              height,
            },
            image,
          );
        }
        if (this.sharedItem.thumbnails.image && this.sharedItem.thumbnails.image.url) {
          const { image } = this.sharedItem.thumbnails;
          const height = 600 * (image.height / image.width);
          return this.$makeCloudImage(
            image.url,
            {
              width: 600,
              height,
            },
            image,
          );
        }
      }
      return null;
    },
  },
  methods: {
    openSharedTemplateInPreview(item) {
      this.iframeSourceForPreview = item.iframeSourceForPreview;
      this.previewActionObject = item.previewActionObject;
      this.itemName = item.itemName;
      this.showPreviewModal = true;
    },
    previewModalClosed() {
      this.showPreviewModal = false;
    },
  },
};
</script>
